import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select';
import { sourceOptions, targetOptions } from '../constants';

function Form({ setError, setVideoDetail, readOnly }) {

  useEffect(() => {
    if(!localStorage.getItem("selectSourceLanguage")) {
      localStorage.setItem("selectSourceLanguage", sourceOptions[0].value);
    }
    if(!localStorage.getItem("selectTargetLanguage")) {
      localStorage.setItem("selectTargetLanguage", targetOptions[0].value);
    }
  }, [])

  return (
    <>
    <div className="w-full flex flex-row items-center gap-1 md:gap-8">
      <div className="w-1/2 flex flex-col gap-1">
        <p className="text-sm md:text-lg font-bold text-gray-400">기본 언어</p>
        <ReactSelect
          options={sourceOptions}
          isDisabled={readOnly}
          defaultValue={localStorage.getItem("selectSourceLanguage")? sourceOptions.find(item => item.value === localStorage.getItem("selectSourceLanguage")) : sourceOptions[0]}
          onChange={(item) => {
            setError(false);
            setVideoDetail(null)
            localStorage.setItem("selectSourceLanguage", item?.value);
          }}
        />
      </div>
      <div className="w-1/2 flex flex-col gap-1">
        <p className="text-sm md:text-lg font-bold text-gray-400">변경 언어</p>
        <ReactSelect
          options={targetOptions}
          isDisabled={readOnly}
          className="w-full"
          defaultValue={localStorage.getItem("selectTargetLanguage")? targetOptions.find(item => item.value === localStorage.getItem("selectTargetLanguage")) : targetOptions[0]}
          onChange={(item) => {
            setError(false);
            setVideoDetail(null)
            localStorage.setItem("selectTargetLanguage", item?.value);
          }}
        />
      </div>
    </div>
    <div className="flex flex-col gap-4">
      <p className="text-sm md:text-lg font-bold text-gray-400">
        Youtube 링크를 입력해주세요.
      </p>
      <div className="w-full flex flex-col gap-8 border border-gray-200 bg-gray-100 p-1 md:p-8 rounded-xl overflow-hidden">
        <label className="flex flex-col items-start gap-1">
          <input
            type="text"
            disabled={readOnly}
            className="w-full rounded-md text-sm md:text-base p-1 md:p-2 border-2 border-gray-300"
            placeholder={"youtube 링크 붙여 넣기"}
            defaultValue={localStorage.getItem("selectedURL")}
            onChange={(e) => {
                setError(false);
                setVideoDetail(null)
                localStorage.setItem("selectedURL", e.target.value);
            }}
          />
        </label>
      </div>
    </div>

  </>

  )
}

export default Form
