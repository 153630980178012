import { env } from "../constants/env";

export const checkCharacterCount = async (timeRange) => {

    let minutes = Math.ceil(Number(`${(Number(timeRange.endHours) * 60 ) + Number(timeRange.endMinutes)}.${timeRange.endSeconds?.replace(0, '')}`))

    const response = await fetch('https://api.elevenlabs.io/v1/user/subscription', {
        method: 'GET',
        headers: {
            'xi-api-key': env.ELEVENLABS_API_KEY
        }
    })

    const res = await response.json();
    if (response.status === 200) {
        if ((Number(minutes) * 2000) < (Number(res.character_limit) - Number(res.character_count)))
            return true;
    }
    else
        return false;

}