export const sourceOptions = [
    { value: "auto", label: "자동감지" },
    { value: "en", label: "영어" },
    { value: "ko", label: "한국어" },
  ];
  export const targetOptions = [
    { value: "en", label: "영어" },
    { value: "ja", label: "일본어" },
    { value: "ko", label: "한국어" },
    { value: "zh", label: "중국어" },
  ];
  