export const env = {
  // SERVER_URL: "http://localhost:5000",
  SERVER_URL: "https://dogdoge.ai",
  FRONT_END_URL: "http://localhost:3000",
  ELEVENLABS_API_KEY: "d0438672c33af289d93fd2e117805af3",
  GCP_API_KEY: "AIzaSyBnnzCNA5cablRn8G5JwDsXDVM--ajFkX4",
  PUBLIC_KEY:"pk_test_51ObFjyHwiSwUO6oiRxZkDAJTz0eLRI2NJD6lhh8rGLilNrn8c9bCVXKIURJriXOI8U4BOXq9SMs5vsB13txMZ8Kj00VT01B7xw",
  CHANNEL_KEY_PORTONE: 'channel-key-7f9157f2-480f-4ce9-9956-5210f0b4bd7c',
  STORE_ID_PORTONE: 'store-eb0f3207-c3e1-4ad1-9e74-f83cb1dfe9a2',
};
