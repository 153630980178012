import React from 'react'

function Button({ loader, error, onClick, title}) {
  return (
    <button onClick={onClick} disabled={loader} className={`flex p-1 text-sm md:text-base md:py-2 w-full  rounded-lg   items-center justify-center  bg-[#3e61fb] text-white ${
        !loader && !error
          ? "cursor-pointer"
          : "cursor-not-allowed"
      }
     `}>
      {
        loader ? 
        "..."
        :
        title
      }
    </button>
  )
}

export default Button
