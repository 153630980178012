import React, { useState } from 'react'
import Home from './pages/Home'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import Footer from './components/Footer';
import EmailPopup from './components/EmailPopup';

function App() {

  const [showEmailPopup, setShowEmailPopup] = useState(false)

  return (
    <div className={`${showEmailPopup && 'fixed w-full'}`}>

      <Home showEmailPopup={showEmailPopup} setShowEmailPopup={setShowEmailPopup}/>
      <Footer />

      {showEmailPopup && <EmailPopup setShowEmailPopup={setShowEmailPopup} />}
      <ToastContainer
        hideProgressBar={true}
        newestOnTop={false}
        rtl={false}
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        autoClose={1000}
      />
    </div>
  )
}

export default App
