import React from "react";

import { toast } from "react-toastify";

const CustomToastify = (
  message,
  message2,
  message3,
  bgColor,
  direction,
  icon,
  flag,
  isInfo
) => {
  const opt = {
    // position: "bottom-left",
    style: {
      backgroundColor: isInfo ? "#CDEDFF" : flag ? "#f7ffed" : "#ffedea",
      width: "300px",
      //   direction: "rtl",
      border: isInfo
        ? "1px solid #42B7E4"
        : flag
        ? "1px solid #4FA32B"
        : "1px solid #FF5449",
    },
  };
  toast(
    <Custom
      message={message}
      message2={message2}
      message3={message3}
      bgColor={bgColor}
      direction={direction}
      icon={icon}
      flag={flag}
      isInfo={isInfo}
    />,
    opt
  );
};

const Custom = ({
  message,
  message2,
  message3,
  bgColor,
  direction,
  icon,
  flag,
  isInfo,
}) => {
  return (
    <div
      className={`flex flex-col h-full ${bgColor} relative `}
      dir={direction === "ltr" ? "rtl" : "ltr"}
    >
      <div className="flex flex-row px-2">
        {/* <div className="flex w-1/6 justify-center  ">
          <img src={icon} alt="" className={`w-6 h-6 `} />
        </div> */}
        <div className="flex flex-col w-5/6">
          <span
            className={`text-[#263238] ${
              direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
            } text-[14px]`}
          >
            {message}
          </span>
          {message2 && (
            <span
              className={`text-[#546E7A] ${
                direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
              } text-[14px]`}
            >
              {message2}
            </span>
          )}
        </div>
      </div>
      {message3 && (
        <div className="flex flex-row h-20">
          <div
            className={`flex flex-row absolute bottom-0 ${
              direction === "ltr" ? "left-0" : "right-0"
            } gap-2 `}
            dir={direction === "ltr" ? "rtl" : "ltr"}
          >
            {/* <img src={Images.Retry} alt="" className={`w-6 h-6 `} /> */}
            <span
              className={` ${
                flag === false ? " text-[#D5481B] " : "text-[#546E7A]"
              } ${
                direction === "ltr" ? "font-primary-rg" : "font-primary-he-rg"
              } text-[14px]`}
            >
              {message3}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomToastify;
