import React, { useState } from 'react'

function EmailPopup({ setShowEmailPopup }) {

    const [error, setError] = useState(false)

    const validateMail = (e) => {
        e.preventDefault()
        const mail = localStorage.getItem("usermail")
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!emailRegex.test(mail)){
            setError('유효한 이메일을 입력해주세요')
            return
        }
        setShowEmailPopup(false)
    }
  return (
    <div className='fixed top-0 left-0 w-full h-screen bg-black/50 flex justify-center items-center '>
        <div className='flex bg-white w-[90%]  md:w-1/3 rounded-md shadow-lg p-2 md:p-4 flex-col gap-5'>
            <form onSubmit={validateMail} className='flex flex-col gap-2 w-full'>
                <h2 className='text-base text-black'>입력하신 이메일주소로 더빙이 완료된 영상을 다운로드 받을 수 있는 링크를 보내드립니다. (영상의 길이에 따라 메일 도착시간이 달라집니다.)</h2>
                <input type='text' required={true} onChange={(e) => {setError(false); localStorage.setItem("usermail", e.target.value)}} placeholder='이메일을 입력해주세요' className='p-1 md:p-2 border text-sm md:text-base  w-full border-gray-700 rounded-md outline-none' />
                <button className='bg-[#3e61fb] text-white rounded-md py-2'>결제진행</button>
                {error && <p className='text-red-500 text-center'>{error}</p>}
            </form>

        </div>
    </div>
  )
}

export default EmailPopup
